import find from 'lodash/find'
/**
 * Enum for Seller Freight Class .
 * @readonly
 * @enum {{key: string, value: string}}
 */

const sellerFreightClassEnum = Object.freeze({
  Auto: { key: 'Auto', value: '', text: 'Estimate my freight class' },
  _50: { key: '_50', value: '_50', text: '50' },
  _55: { key: '_55', value: '_55', text: '55' },
  _60: { key: '_60', value: '_60', text: '60' },
  _65: { key: '_65', value: '_65', text: '65' },
  _70: { key: '_70', value: '_70', text: '70' },
  _77_5: { key: '_77_5', value: '_77_5', text: '77.5' },
  _85: { key: '_85', value: '_85', text: '85' },
  _92_5: { key: '_92_5', value: '_92_5', text: '92.5' },
  _100: { key: '_100', value: '_100', text: '100' },
  _110: { key: '_110', value: '_110', text: '110' },
  _125: { key: '_125', value: '_125', text: '125' },
  _150: { key: '_150', value: '_150', text: '150' },
  _175: { key: '_175', value: '_175', text: '175' },
  _200: { key: '_200', value: '_200', text: '200' },
  _250: { key: '_250', value: '_250', text: '250' },
  _300: { key: '_300', value: '_300', text: '300' },
  _400: { key: '_400', value: '_400', text: '400' },
  _500: { key: '_500', value: '_500', text: '500' },
})

function getSellerFreightClassEnum(predicate, predicateKey = 'key') {
  const result = find(sellerFreightClassEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getSellerFreightClassEnumList() {
  const options = []
  Object.keys(sellerFreightClassEnum).map(key => options.push({ value: sellerFreightClassEnum[key].value, text: sellerFreightClassEnum[key].text }))
  return options
}

export default sellerFreightClassEnum

export {
  getSellerFreightClassEnum,
  getSellerFreightClassEnumList,
}
