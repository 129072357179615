<template>
  <div>
    <div class="d-flex p-1">
      <dx-util-text-box id="amazonProNumber" ref="amazonProNumber" v-model="bol.proNumber" label="Amazon Pro No" label-mode="floating" class="mr-1" @enterKey="generateBOL" />
      <dx-util-text-box v-model="bol.appointmentId" label="Appointment ID" label-mode="floating" class="mr-1" @enterKey="generateBOL" />
      <dx-util-button id="bolGenerator" icon="icon icon-psh-package-3" text="Generate BOL" type="success" class="mr-1 mt-half" style="width:160px" @click="generateBOL" />
      <dx-util-button id="pdfGenerator" :disabled="!showBOL" icon="pdffile" text="Generate PDF" type="success" class="mr-1 mt-half" style="width:160px" @click="generatePDF" />
      <dx-util-button id="bolUploader" icon="icon icon-psh-package-3" text="Upload BOL" type="default" class="mr-1 mt-half" style="width:160px" @click="uploadSignedBOL" />
    </div>
    <div v-if="showBOL" class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="text-center mt-1">
            <h2 class="my-0">
              BILL OF LADING
            </h2>
            <small class="my-0">
              Generated At: {{ generateToday() }}
            </small>
          </div>
        </div>
        <div class="col-12 px-2">
          <div class="d-flex">
            <span class="mr-1">Date:</span>
            <editable-input-box v-model="bol.date" />
          </div>
        </div>
        <!-- SHIP FROM AND TO PART (LEFT SIDE) -->
        <div class="col-6 px-1 mx-0">
          <div class="border mt-1">
            <div class="bg-black text-center py-half h4 text-white">
              SHIP FROM
            </div>
            <div class="mt-1">
              <dx-util-select-box
                v-model="selectedShipFromAddress"
                :data-source="shipFromAddresses"
                display-expr="text"
                value-expr="id"
                item-template="item"
                label-mode="floating"
                label="Ship From"
                class="my-1 mx-1 flex-grow-1"
                :show-clear-button="true"
                @value-changed="fillShipFromAddress"
              >
                <template #item="{ data }">
                  <div v-html="data.textHtml" />
                </template>
                <dx-util-validator>
                  <dx-util-required-rule
                    message="Pickup address is a required field"
                  />
                </dx-util-validator>
              </dx-util-select-box>
            </div>
            <table id="shipTo" class="text-left my-1 mx-1">
              <tr>
                <td>Name:</td>
                <td><editable-input-box v-model="shipFrom.name" /></td>
              </tr>
              <tr>
                <td>Address:</td>
                <td><editable-input-box v-model="shipFrom.address" /></td>
              </tr>
              <tr>
                <td>City/State/Zip:</td>
                <td><editable-input-box v-model="shipFrom.cityStateZip" /></td>
              </tr>
            </table>
          </div>
          <div class="border mt-1">
            <div class="bg-black py-half text-center h4 text-white">
              SHIP TO
            </div>
            <div class="mt-1">
              <dx-util-select-box
                v-model="selectedAmazonAddress"
                :data-source="amazonAddresses"
                display-expr="text"
                value-expr="id"
                search-mode="contains"
                :show-data-before-search="false"
                :search-timeout="200"
                :search-enabled="true"
                search-expr="code"
                :min-search-length="0"
                label-mode="floating"
                label="Amazon Address"
                class="my-1 mx-1 flex-grow-1"
                :show-clear-button="true"
                @selection-changed="fillShipToAddress"
              />
            </div>
            <table id="shipTo" class="text-left my-1 mx-1">
              <tr>
                <td>Name:</td>
                <td><editable-input-box v-model="shipTo.name" /></td>
              </tr>
              <tr>
                <td>Address:</td>
                <td><editable-input-box v-model="shipTo.address" /></td>
              </tr>
              <tr>
                <td>City/State/Zip:</td>
                <td><editable-input-box v-model="shipTo.cityStateZip" /></td>
              </tr>
            </table>
          </div>
        </div>
          <!-- BOL INFO PART (RIGHT SIDE) -->
        <div class="col-6 px-1 mx-0">
          <div class="border p-1">
            <table id="bolInfo" class="text-left">
              <tr>
                <td class="firstCol">
                  Bill of Lading Number:
                </td>
                <td>
                  <editable-input-box v-model="bol.number" />
                </td>
              </tr>
              <tr>
                <td class="firstCol">
                  Amazon PO ID:
                </td>
                <td>
                  <editable-input-box v-model="bol.amazonPoId" />
                </td>
              </tr>
              <tr>
                <td class="firstCol">
                  Amazon Reference Number:
                </td>
                <td>
                  <editable-input-box v-model="bol.amazonReferenceNo" />
                </td>
              </tr>
              <tr class="seperator">
                <td class="firstCol">
                  Carrier Name:
                </td>
                <td>
                  <editable-input-box v-model="bol.carrierName" />
                </td>
              </tr>
              <tr class="seperator">
                <td class="firstCol">
                  SCAC:
                </td>
                <td>
                  <editable-input-box v-model="bol.scac" />
                </td>
              </tr>
              <tr>
                <td class="firstCol">
                  Pro Number:
                </td>
                <td>
                  <editable-input-box v-model="bol.proNumber" />
                </td>
              </tr>
            </table>
          </div>
          <div class="border">
            <div class="bg-black py-half h4 text-center text-white">
              THIRD PARTY FREIGHT CHARGES BILL TO
            </div>
            <table id="shipTo" class="text-left my-1 mx-1">
              <tr>
                <td>Name:</td>
                <td><editable-input-box v-model="billTo.name" /></td>
              </tr>
              <tr>
                <td>Address:</td>
                <td><editable-input-box v-model="billTo.address" /></td>
              </tr>
              <tr>
                <td>City/State/Zip:</td>
                <td><editable-input-box v-model="billTo.cityStateZip" /></td>
              </tr>
            </table>
            <div class="font-weight-bold border border-top mt-2 p-1">
              Freight Charge Terms:
              <span class="font-italic">
                (freight charges are prepaid unless market otherwise)
              </span>
              <div class="p-1">
                <dx-util-radio-group
                  v-model="selectedTerm"
                  :data-source="['Pre Paid', 'Collect', '3rd Party']"
                  layout="horizontal"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-1 mt-1">
          <div class="border">
            <div class="bg-black py-half text-center h4 text-white">
              SHIPMENT DETAILS
            </div>
            <div class="p-1">
              <table id="bolInfo" class="text-left">
                <tr>
                  <td class="firstCol">
                    Total Cartons:
                  </td>
                  <td>
                    <editable-input-box v-model="shipmentDetails.totalCartons" />
                  </td>
                </tr>
                <tr>
                  <td class="firstCol">
                    Num. Stackable Pallets:
                  </td>
                  <td>
                    <editable-input-box v-model="shipmentDetails.stackablePallets" />
                  </td>
                </tr>
                <tr>
                  <td class="firstCol">
                    Num. Unstackable Pallets:
                  </td>
                  <td>
                    <editable-input-box v-model="shipmentDetails.unstackablePallets" />
                  </td>
                </tr>
                <tr class="seperator">
                  <td class="firstCol">
                    Total Shipment Weight:
                  </td>
                  <td class="d-flex align-items-center">
                    <editable-input-box v-model="shipmentDetails.totalShipmentWeight" />
                    <span class="text-primary pl-half">pounds</span>
                  </td>
                </tr>
                <tr>
                  <td class="firstCol">
                    Total Shipment Volume:
                  </td>
                  <td class="d-flex align-items-center">
                    <editable-input-box v-model="shipmentDetails.totalShipmentVolume" />
                    <span class="text-primary pl-half">cubic feet</span>
                  </td>
                </tr>
                <tr class="seperator">
                  <td class="firstCol">
                    Freight Class:
                  </td>
                  <td>
                    <dx-util-select-box v-model="shipmentDetails.freightClass" :data-source="freightClasses" value-expr="text" display-expr="text" />
                  </td>
                </tr>
                <tr>
                  <td class="firstCol">
                    Shipment Type:
                  </td>
                  <td>
                    <editable-input-box v-model="shipmentDetails.shipmentType" />
                  </td>
                </tr>
                <tr>
                  <td class="firstCol">
                    Total Units:
                  </td>
                  <td>
                    <editable-input-box v-model="shipmentDetails.totalUnits" />
                  </td>
                </tr>
                <tr>
                  <td class="firstCol">
                    Declared (Insurable) Value:
                  </td>
                  <td class="d-flex align-items-center">
                    <editable-input-box v-model="shipmentDetails.totalValue" />
                    <span class="text-primary pl-half">USD</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 px-1 my-1">
          <div class="py-half d-flex align-items-center">
            <div class="px-1">
              <dx-util-text-box
                v-model="batchName"
                placeholder="Batch name"
                @enterKey="addJob"
              />
            </div>
            <div class="px-1">
              <dx-util-button icon="add" text="ADD JOB TO BOL" type="success" @click="addJob" />
            </div>
          </div>
          <div class="mt-2">
            <table id="jobs" class="job-table">
              <thead>
                <tr>
                  <th style="width:5%">
                    ACC#
                  </th>
                  <th style="width:25%">
                    JOB NO
                  </th>
                  <th style="width:20%">
                    AMAZON ID
                  </th>
                  <th style="width:10%">
                    DESTINATION
                  </th>
                  <th>CTNS</th>
                  <th>PALLET</th>
                  <th>GROSS WEIGHT LBS</th>
                  <th class="narrower"></th>
                </tr>
              </thead>
              <tbody v-if="jobList && jobList.length === 0">
                <tr>
                  <td colspan="8" class="p-1">
                    You have not added any jobs / batches to this Bill of Ladings. You can either enter
                    <span class="text-warning">
                      PRO NUMBER to the top of the page and click Generate BOL button
                    </span>
                    or
                    <span class="text-warning">
                      use the ADD JOB TO BOL section just above this table.
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr v-for="job in jobList" :key="job.jobNo">
                  <td>
                    {{ job.accountNo }}
                  </td>
                  <td>
                    <editable-input-box v-model="job.jobNo" />
                  </td>
                  <td>
                    <editable-input-box v-model="job.amazonId" />
                  </td>
                  <td>
                    <destination-box :destination="job.destCode" :first-one="jobList[0].destCode" />
                  </td>
                  <td>
                    <editable-input-box v-model="job.cartonCount" />
                  </td>
                  <td>
                    <editable-input-box v-model="job.palletCount" />
                  </td>
                  <td>
                    <div class="d-flex justify-content-between">
                      <editable-input-box v-model="job.itemCount" />
                      <p-icon v-if="job.itemCount == 0" name="bi-exclamation-circle-fill" color="red" />
                    </div>
                  </td>
                  <td class="text-center ">
                    <p-icon name="bi-trash" color="danger" role="button" @click.native="removeJob(job.jobNo)" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12 px-0 my-1">
          <div class="table-container p-1 border">
            <table id="formFooter" class="form-table">
              <tr>
                <td>
                  <div>
                    Where the rate is dependent on value, shippers are required to state specifically in writing the agreed
                    or declared value of the property as follows:
                  </div>
                  <span>
                    "The agreed or declared value of the property is specifically stated by the shipper
                  </span>
                  <span class="d-flex align-items-center">
                    <span class="px-half">
                      to be not exceeding
                    </span>
                    <editable-input-box v-model="declared.value" />
                    <span class="px-half">per</span>
                    <editable-input-box v-model="declared.type" />."
                  </span>
                </td>
                <td class="special-border">
                  <div class="font-weight-bold p-1">
                    <div class="d-flex align-items-center">
                      <h4 class="">
                        COD Amount:
                      </h4>
                      <h4 class="px-1">
                        $
                      </h4>
                      <editable-input-box v-model="codAmount" />
                    </div>
                    <div class="d-flex align-items mt-1">
                      <span class="mr-1">
                        Fee terms
                      </span>
                      <dx-util-radio-group
                        v-model="selectedFeeTerm"
                        :data-source="['Pre Paid', 'Collect', 'Customer check acceptable']"
                        layout="horizontal"
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <h5>
                    NOTE Liability Limitation for loss or damage in this shipment may be applicable. See 49 U.S.C &#9632; 14706(c)(1)(A) and (B).
                  </h5>
                </td>
              </tr>
              <tr>
                <td>
                  RECEIVED, subject to individually determined rates or contracts that have been agreed upon in writing between the carrier and shipper,
                  if applicable, otherwise to the rates, classifications and rules that have been established by the carrier and are available to the shipper,
                  on request, and to all applicable state and federal regulations.
                </td>
                <td>
                  The carrier shall not make delivery of this shipment without payment of freight and all other lawful charges.
                  <div class="mt-3">
                    ____________________________________
                    <span class="px-1 font-weight-bold">Shipper Signature</span>
                  </div>
                </td>
              </tr>
            </table>
            <table id="formFooter" class="form-table mt-half">
              <tr>
                <td style="width:50% !important">
                  <h4>SHIPPER SIGNATURE / DATE</h4>
                  <div>
                    This is to certify that the above named materials are properly classified, packaged, marked and labeled,
                    and are in proper condition for transportation according to the applicable regulations of the DOT.
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <div class="w-40">
                      <h5 class="underline">
                        Trailer Loaded
                      </h5>
                      <div>
                        <dx-util-radio-group
                          v-model="loadedBy"
                          :data-source="['By Shipper', 'By Driver']"
                        />
                      </div>
                    </div>
                    <div class="w-60">
                      <h5 class="underline">
                        Freight Counted
                      </h5>
                      <dx-util-radio-group
                        v-model="countedBy"
                        :data-source="['By Shipper', 'By Driver/pallets said to contain', 'By Driver/Pieces']"
                        layout="horizontal"
                      />
                    </div>
                  </div>
                </td>
                <td style="width:60% !important">
                  <h4>CARRIER SIGNATURE / DATE</h4>
                  <span class="smaller-font">
                    Carrier acknowledges receipt of packages and required placards.
                    Carrier certifies emergency response information was made available and/or carrier has the DOT emergency response guidebook
                    or equivalent documentation in the vehicle.
                    <strong class="font-italic">
                      Property described above is received in good order, except as noted.
                    </strong>
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <vue-html2pdf
          ref="html2Pdf"
          :show-layout="false"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="false"
          :filename="`bol-${bol.proNumber}`"
          :pdf-quality="2"
          :manual-pagination="false"
          :paginate-elements-by-height="10000"
          pdf-format="letter"
          pdf-orientation="portrait"
          :pdf-content-width="'810px'"
          @hasDownloaded="hasGenerated"
      >
        <section id="amazonFreightContent" slot="pdf-content" class="p-3 mt-2" :class="isPdfGenerating ? '' : 'hidden'">
          <div class="row text-black border border-black">
            <div class="col-12">
              <div class="text-center mt-0">
                <h4 class="my-0 text-black">
                  BILL OF LADING
                </h4>
              </div>
            </div>
            <div class="col-12 px-1">
              <div class="d-flex">
                <span class="mr-1">Date:</span>{{ bol.date }}
              </div>
            </div>
            <!-- SHIP FROM AND TO PART (LEFT SIDE) -->
            <div class="col-6 px-0 mx-0">
              <div class="border border-black mt-0">
                <small class="bg-black d-block text-center text-white">
                  SHIP FROM
                </small>
                <table id="shipTo" class="text-left my-half mx-half">
                  <tr>
                    <td>
                      {{ shipFrom.name }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ shipFrom.address }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ shipFrom.cityStateZip }}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="border border-black mt-0">
                <small class="bg-black d-block text-center text-white">
                  SHIP TO
                </small>
                <table id="shipTo" class="text-left my-half mx-half">
                  <tr>
                    <td>{{ shipTo.name }}</td>
                  </tr>
                  <tr>
                    <td>{{ shipTo.address }}</td>
                  </tr>
                  <tr>
                    <td>{{ shipTo.cityStateZip }}</td>
                  </tr>
                </table>
              </div>
            </div>
              <!-- BOL INFO PART (RIGHT SIDE) -->
            <div class="col-6 px-0 mx-0">
              <div class="border border-black p-0">
                <table id="bolInfo" class="text-left">
                  <tr>
                    <td>
                      <strong>
                        Bill of Lading Number:
                      </strong>
                    </td>
                    <td>
                      {{ bol.number }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        Amazon PO ID:
                      </strong>
                    </td>
                    <td>
                      {{ bol.amazonPoId }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        Amazon Reference Number:
                      </strong>
                    </td>
                    <td>
                      {{ bol.amazonReferenceNo }}
                    </td>
                  </tr>
                  <tr class="seperator">
                    <td>
                      <strong>
                        Carrier Name:
                      </strong>
                    </td>
                    <td>
                      {{ bol.carrierName }}
                    </td>
                  </tr>
                  <tr class="seperator">
                    <td>
                      <strong>
                        SCAC:
                      </strong>
                    </td>
                    <td>
                      {{ bol.scac }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        Pro Number:
                      </strong>
                    </td>
                    <td>
                      {{ bol.proNumber }}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="border border-black">
                <small class="bg-black d-block text-center text-white">
                  THIRD PARTY FREIGHT CHARGES BILL TO:
                </small>
                <table id="shipToPrint" class="text-left">
                  <tr>
                    <td>
                      <small>
                        <strong>
                          Name:
                        </strong>
                      </small>
                    </td>
                    <td>{{ billTo.name }}</td>
                  </tr>
                  <tr>
                    <td>
                      <small>
                        <strong>
                          Address:
                        </strong>
                      </small>
                    </td>
                    <td>{{ billTo.address }}</td>
                  </tr>
                  <tr>
                    <td>
                      <small>
                        <strong>
                          City/State/Zip:
                        </strong>
                      </small>
                    </td>
                    <td>{{ billTo.cityStateZip }}</td>
                  </tr>
                </table>
                <div class="font-weight-bold border border-top">
                  <span class="smaller-font">
                    Freight Charge Terms:
                  </span>
                  <span class="font-italic smaller-font">
                    (freight charges are prepaid unless market otherwise)
                  </span>
                  <div class="p-half text-black">
                    <p-icon :name="selectedTerm === 'Pre Paid'? 'bi-x-square' : 'bi-square'" size="14px" /><span class="mr-1"> Pre Paid</span>
                    <p-icon :name="selectedTerm === 'Collect'? 'bi-x-square' : 'bi-square'" size="14px" /><span class="mr-1"> Collect</span>
                    <p-icon :name="selectedTerm === '3rd Party'? 'bi-x-square' : 'bi-square'" size="14px" /><span class=""> 3rd Party</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 px-0 mt-0">
              <div class="border border-black">
                <small class="bg-black d-block text-center text-white">
                  SHIPMENT DETAILS
                </small>
                <div class="p-half">
                  <table id="bolInfoPrint" class="text-left">
                    <tr>
                      <td class="firstCol">
                        Total Cartons:
                      </td>
                      <td>
                        {{ shipmentDetails.totalCartons }}
                      </td>
                    </tr>
                    <tr>
                      <td class="firstCol">
                        Numb. Stackable Pallets:
                      </td>
                      <td>
                        {{ shipmentDetails.stackablePallets }}
                      </td>
                    </tr>
                    <tr>
                      <td class="firstCol">
                        Num. Unstackable Pallets:
                      </td>
                      <td>
                        {{ shipmentDetails.unstackablePallets }}
                      </td>
                    </tr>
                    <tr class="seperator">
                      <td class="firstCol">
                        Total Shipment Weight:
                      </td>
                      <td class="d-flex align-items-center">
                        {{ shipmentDetails.totalShipmentWeight }} pounds
                      </td>
                    </tr>
                    <tr>
                      <td class="firstCol">
                        Total Shipment Volume:
                      </td>
                      <td class="d-flex align-items-center">
                        {{ shipmentDetails.totalShipmentVolume }} cubic feet
                      </td>
                    </tr>
                    <tr class="seperator">
                      <td class="firstCol">
                        Freight Class:
                      </td>
                      <td>
                        {{ shipmentDetails.freightClass }}
                      </td>
                    </tr>
                    <tr>
                      <td class="firstCol">
                        Shipment Type:
                      </td>
                      <td>
                        {{ shipmentDetails.shipmentType }}
                      </td>
                    </tr>
                    <tr>
                      <td class="firstCol">
                        Total Units:
                      </td>
                      <td>
                        {{ shipmentDetails.totalUnits }}
                      </td>
                    </tr>
                    <tr>
                      <td class="firstCol">
                        Declared (Insurable) Value:
                      </td>
                      <td class="d-flex align-items-center">
                        {{ shipmentDetails.totalValue }} USD
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="jobList && jobList.length > 0" class="col-12 px-0 mx-0 my-0">
              <div v-for="(jobs, index) in jobBatches" :key="index" class="mt-3">
                <table id="jobsPrint" class="job-table">
                  <thead>
                    <tr>
                      <th>
                        JOB NO
                      </th>
                      <th style="width:20%">
                        AMAZON ID
                      </th>
                      <th>CTNS</th>
                      <th>PALLET</th>
                      <th>GROSS WEIGHT LBS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="job in jobs" :key="job.jobNo">
                      <td>
                        {{ job.jobNo }}
                      </td>
                      <td>
                        {{ job.amazonId }}
                      </td>
                      <td>
                        {{ job.cartonCount }}
                      </td>
                      <td>
                        {{ job.palletCount }}
                      </td>
                      <td>
                        {{ job.itemCount }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="index !== jobBatches.length-1" class="html2pdf__page-break" />
              </div>
            </div>
            <div class="col-12 px-0 my-0 mx-0">
              <div class="table-container p-0 border">
                <table id="jobsPrint" class="form-table">
                  <tr>
                    <td>
                      <div class="smaller-font">
                        Where the rate is dependent on value, shippers are required to state specifically in writing the agreed
                        or declared value of the property as follows:
                      </div>
                      <div class="smaller-font">
                        "The agreed or declared value of the property is specifically stated by the shipper
                        to be not exceeding {{ declared.value }} per {{ declared.type }}."
                      </div>
                    </td>
                    <td class="p-0">
                      <div class="font-weight-bold px-half border-bold">
                        <div class="d-flex align-items-center text-black">
                          <h6 class="text-black my-0 py-0">
                            COD Amount: $ {{ codAmount }}
                          </h6>
                        </div>
                        <div>
                          Fee terms
                        </div>
                        <div class="mb-half">
                          <p-icon :name="selectedFeeTerm === 'Pre Paid'? 'bi-x-square' : 'bi-square'" size="14px" /><span class="mr-1"> Pre Paid</span>
                          <p-icon :name="selectedFeeTerm === 'Collect'? 'bi-x-square' : 'bi-square'" size="14px" /><span class="mr-1"> Collect</span>
                          <p-icon :name="selectedFeeTerm === 'Customer check acceptable'? 'bi-x-square' : 'bi-square'" size="14px" /><span class=""> Customer check acceptable</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <small>
                        <strong>
                          NOTE Liability Limitation for loss or damage in this shipment may be applicable. See 49 U.S.C &#9632; 14706(c)(1)(A) and (B).
                        </strong>
                      </small>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="smaller-font">
                        RECEIVED, subject to individually determined rates or contracts that have been agreed upon in writing between the carrier and shipper,
                        if applicable, otherwise to the rates, classifications and rules that have been established by the carrier and are available to the shipper,
                        on request, and to all applicable state and federal regulations.
                      </div>
                    </td>
                    <td>
                      <div class="smaller-font">
                        The carrier shall not make delivery of this shipment without payment of freight and all other lawful charges.
                      </div>
                      <div class="mt-0 py-0 d-flex">
                        ________________________
                        <div class="px-1 font-weight-bold">
                          Shipper Signature
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <table id="formFooterPrint" class="form-table">
                  <tr>
                    <td style="width:50% !important">
                      <div class="font-weight-bold">
                        SHIPPER SIGNATURE / DATE
                        </div>
                      <div class="smaller-font">
                        This is to certify that the above named materials are properly classified, packaged, marked and labeled,
                        and are in proper condition for transportation according to the applicable regulations of the DOT.
                      </div>
                    </td>
                    <td>
                      <div class="d-flex">
                        <div class="w-40">
                          <div class="underline">
                            Trailer Loaded
                          </div>
                          <div class="checkbox-small">
                            <p-icon :name="loadedBy === 'By Shipper'? 'bi-x-square' : 'bi-square'" size="14px" /><span class="mr-1"> By Shipper</span>
                            <p-icon :name="loadedBy === 'By Driver'? 'bi-x-square' : 'bi-square'" size="14px" /><span class="mr-1"> By Driver</span>
                          </div>
                        </div>
                        <div class="w-60">
                          <div class="underline">
                            Freight Counted
                          </div>
                          <div class="checkbox-small d-flex flex-column">
                            <span class="d-flex align-items-center">
                              <p-icon :name="countedBy === 'By Shipper'? 'bi-x-square' : 'bi-square'" size="14px" /> By Shipper
                            </span>
                            <span class="d-flex align-items-center">
                              <p-icon :name="countedBy === 'By Driver/pallets said to contain'? 'bi-x-square' : 'bi-square'" size="14px" /> By Driver/pallets said to contain
                            </span>
                            <span class="d-flex align-items-center">
                              <p-icon :name="countedBy === 'By Driver/Pieces'? 'bi-x-square' : 'bi-square'" size="14px" /> By Driver/Pieces
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style="width:60% !important">
                      <div class="font-weight-bold ">
                        CARRIER SIGNATURE / DATE
                        </div>
                      <div class="smaller-font">
                        Carrier acknowledges receipt of packages and required placards.
                        Carrier certifies emergency response information was made available and/or carrier has the DOT emergency response guidebook
                        or equivalent documentation in the vehicle.
                        <strong class="font-italic">
                          Property described above is received in good order, except as noted.
                        </strong>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
      </div>
    </div>
    <div v-if="showUpload">
      <service-folder-file-manager
        ref="refServiceFolderFileManager"
        :company-id="userCompanyId"
        :upload-document-class="'BATCH_PRO_NUMBER'"
        :upload-document-classes="['BATCH_PRO_NUMBER']"
        :entity-folder-id="bol.proNumber"
        :service-folder="'BOL_OUTBOUND'"
      />
    </div>
  </div>
</template>

<script>
import {
  reactive, onMounted, ref, nextTick,
} from '@vue/composition-api'
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf'
import { getSellerFreightClassEnumList } from '@/enums/sellerFreightClassEnum'
import { Notify } from '@/@robustshell/utils'
import commonService from '@/http/requests/common/commonEntityService'
import billOfLadingService from '@/http/requests/outbound/billOfLadingService.js'
import useCurrentUser from '@/libs/app/current-user'
import EditableInputBox from '../EditableInputBox.vue'
import DestinationBox from '../DestinationBox.vue'

export default {
  components: {
    'service-folder-file-manager': () => import('@components/service-folder/ServiceFolderFileManager.vue'),
    'editable-input-box': EditableInputBox,
    'destination-box': DestinationBox,
    VueHtml2pdf,
  },
  setup() {
    const {
      userAccountNo, userCompanyId, // userFullname, userWarehouseId,
    } = useCurrentUser()

    const showBOL = ref(false)
    const showUpload = ref(false)
    const selectedTerm = ref('Pre Paid')
    const selectedFeeTerm = ref('Pre Paid')
    const loadedBy = ref('By Shipper')
    const countedBy = ref('By Shipper')
    const codAmount = ref('')
    const bol = reactive({
      date: '',
      number: '',
      appointmentId: '',
      amazonPoId: '',
      amazonRefNo: '',
      carrierName: 'Amazon Freight LTL',
      scac: 'AMZX',
      proNumber: '',
    })

    const shipFrom = reactive({
      name: '',
      address: '',
      cityStateZip: '',
    })

    const shipTo = reactive({
      name: '',
      address: '',
      cityStateZip: '',
    })

    const billTo = reactive({
      name: '',
      address: '',
      cityStateZip: '',
    })

    const declared = reactive({
      value: '',
      type: '',
    })

    const amazonProNumber = ref(null)

    function setProNumberFocus() {
      amazonProNumber.value.instance.focus()
    }

    function uploadSignedBOL() {
      showBOL.value = false
      if (bol.proNumber === '') {
        Notify.warning('Please enter the BOL Number to upload a file')
        setProNumberFocus()
        // eslint-disable-next-line no-useless-return
        return
      }
      showUpload.value = true
    }

    function generateToday() {
      const date = moment()
      const formattedDate = date.format('YYYY-MM-DD')
      return formattedDate
    }

    function nullCheck(value) {
      return value != null && value !== undefined ? value : ''
    }

    const shipFromAddresses = ref([])
    const selectedShipFromAddress = ref('')

    function fillShipFromAddress(address) {
      shipFrom.name = address.raw.name
      shipFrom.address = `${address.raw.line1} ${address.raw.line2 || ''} ${address.raw.line3 || ''} Suite #${userAccountNo.value}`
      shipFrom.cityStateZip = `${address.raw.city} ${address.raw.state} ${address.raw.zipcode}`
    }

    async function getShipFromAddresses() {
      shipFromAddresses.value.length = 0
      commonService
        .fetchAddressesByTenantAndCompany(userCompanyId.value)
        .then(result => {
          const { data } = result
          data.forEach(item => {
            let suiteNumber = ''
            if (item.commonHub.commonHubType === 'warehouse') {
              suiteNumber = `Suite # ${userAccountNo.value}`
            }
            const line = `${nullCheck(item.line1)} ${nullCheck(item.line2)} ${nullCheck(item.line3)}`
            const addressStringHtml = `${item.name}<br />${line} ${suiteNumber}<br />${item.city}, ${item.state} ${item.zipcode}`
            const addressString = `${item.name} ${line} ${suiteNumber} ${item.city}, ${item.state} ${item.zipcode}`
            shipFromAddresses.value.push({
              id: item.id,
              textHtml: addressStringHtml,
              text: addressString,
              raw: item,
              primary: item.primary,
            })
          })
        })
        .then(() => {
          nextTick(() => {
            selectedShipFromAddress.value = shipFromAddresses.value.filter(el => el.primary === true)[0].id
            const address = shipFromAddresses.value.filter(el => el.primary === true)[0]
            fillShipFromAddress(address)
          })
        })
    }

    const amazonAddresses = ref([])
    const selectedAmazonAddress = ref('')

    async function getAmazonAddresses() {
      commonService.getAmazonAddresses().then(res => {
        const { data } = res
        data.body.forEach((el, i) => {
          data.body[i].text = `${el.countryCode}:${el.code} - (${el.city}/${el.state})`
        })
        amazonAddresses.value.length = 0
        amazonAddresses.value = [...data.body]
      })
    }

    function fillShipToAddress(e) {
      const address = e.selectedItem
      shipTo.name = address.name
      shipTo.address = `${nullCheck(address.line1)} ${nullCheck(address.line2)}`
      shipTo.cityStateZip = `${address.city} ${address.state} ${address.zipcode}`
    }

    const shipmentDetails = reactive({
      totalCartons: '',
      stackablePallets: 0,
      unstackablePallets: 1,
      totalShipmentWeight: '',
      totalShipmentVolume: '',
      freightClass: '',
      shipmentType: 'LTL',
      totalUnits: '',
      totalValue: '',
    })

    const jobBatches = ref([])
    const jobList = ref([])

    function createChunks(jobs) {
      const firstChunkSize = 13
      const nextChunkSize = 40

      const chunks = []
      let startIndex = 0

      chunks.push(jobs.slice(startIndex, startIndex + firstChunkSize))
      startIndex += firstChunkSize

      while (startIndex + nextChunkSize < jobs.length) {
        chunks.push(jobs.slice(startIndex, startIndex + nextChunkSize))
        startIndex += nextChunkSize
      }

      if (startIndex < jobs.length) {
        chunks.push(jobs.slice(startIndex))
      }
      return chunks
    }

    function calculateTotalCartonsNo() {
      let totalCartonCount = 0

      for (let i = 0; i < jobList.value.length; i++) {
        if (typeof jobList.value[i].cartonCount === 'number') {
          totalCartonCount += jobList.value[i].cartonCount
        }
      }
      shipmentDetails.totalCartons = totalCartonCount
    }

    function calculateTotalWeightOrUnit() {
      let totalWeight = 0

      for (let i = 0; i < jobList.value.length; i++) {
        if (typeof jobList.value[i].cartonCount === 'number') {
          totalWeight += jobList.value[i].itemCount
        }
      }
      shipmentDetails.totalShipmentWeight = totalWeight
      shipmentDetails.totalUnits = totalWeight
    }

    async function getBOLData() {
      const bolContent = await billOfLadingService.getBolByProNumber(bol.proNumber)
      jobList.value = bolContent.batches
      jobBatches.value = createChunks(jobList.value)

      shipTo.name = nullCheck(bolContent?.dropAddress?.name)
      shipTo.address = `${nullCheck(bolContent?.dropAddress?.line1)}
                              ${nullCheck(bolContent?.dropAddress?.line2)}`
      shipTo.cityStateZip = `${nullCheck(bolContent?.dropAddress?.city)}
                                   ${nullCheck(bolContent?.dropAddress?.state)}
                                   ${nullCheck(bolContent?.dropAddress?.zipcode)}`
      selectedAmazonAddress.value = bolContent.dropAddress.id
      calculateTotalCartonsNo()
      calculateTotalWeightOrUnit()
    }

    const batchName = ref('')
    async function addJob() {
      const jobNumbers = jobList.value.map(el => el.jobNo)
      if (jobNumbers.includes(batchName.value)) {
        Notify.warning('The batch is already in the job list')
        return
      }
      const jobDetails = await billOfLadingService.getBolJobByBatchName(batchName.value)
      jobList.value.unshift(jobDetails.batches[0])
      jobBatches.value = createChunks(jobList.value)
      calculateTotalCartonsNo()
      calculateTotalWeightOrUnit()
    }

    async function removeJob(e) {
      jobList.value = jobList.value.filter(el => el.jobNo !== e)
      jobBatches.value = createChunks(jobList.value)
      calculateTotalCartonsNo()
      calculateTotalWeightOrUnit()
    }

    async function generateBOL() {
      if (bol.proNumber === '') {
        Notify.warning('You should enter a BOL Pro Number to create a BOL')
        return
      }
      showBOL.value = true
      await getBOLData()
    }

    const isPdfGenerating = ref(false)
    function hasGenerated() {
      isPdfGenerating.value = false
    }

    const freightClasses = ref(getSellerFreightClassEnumList())
    const selectedFreightClass = ref(null)

    onMounted(async () => {
      bol.date = generateToday()
      await getShipFromAddresses()
      await getAmazonAddresses()
      if (bol.proNumber) {
        await getBOLData()
      }
    })

    return {
      codAmount,
      showBOL,
      bol,
      shipFrom,
      shipTo,
      billTo,
      shipFromAddresses,
      selectedShipFromAddress,
      amazonAddresses,
      selectedAmazonAddress,
      shipmentDetails,
      showUpload,
      declared,
      amazonProNumber,
      jobList,
      batchName,
      isPdfGenerating,
      selectedTerm,
      selectedFeeTerm,
      loadedBy,
      countedBy,
      jobBatches,
      freightClasses,
      selectedFreightClass,
      userCompanyId,
      generateBOL,
      uploadSignedBOL,
      generateToday,
      getShipFromAddresses,
      fillShipFromAddress,
      fillShipToAddress,
      addJob,
      removeJob,
      hasGenerated,
      calculateTotalCartonsNo,
    }
  },
  methods: {
    generatePDF() {
      this.isPdfGenerating = true
      this.$refs.html2Pdf.generatePdf()
    },
  },
}
</script>

<style lang="scss" scoped>
#shipTo td {
  padding-right: 24px;
}

#bolInfo table {
  width: 100%;
  height: 100%;
}

#bolInfo .firstCol {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-right: 36px;
}

#bolInfoPrint .firstCol {
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  padding-right: 36px;
}
.seperator {
  border-top: 1px solid #364160;
}

#bolInfo td {
  vertical-align: middle;
}

.table-container {
  width: 100%;
  height: 100%;
}

.form-table {
  width: 100%;
  table-layout: fixed;
}

#formFooter td {
  width: 50%;
  vertical-align: center;
  box-sizing: border-box;
  border: 1px solid black;
  padding: 10px;
}

#formFooterPrint td {
  width: 50%;
  vertical-align: center;
  box-sizing: border-box;
  border: 1px solid black;
  padding: 3px;
  line-height: 12px;
}

#shipToPrint td {
  padding-left: 1px;
  font-size: small;
}

.special-border {
  box-sizing: border-box;
  border: 4px solid black !important;
}

.border-bold {
  border: 3px solid black;
}

.underline {
  text-decoration: underline;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px !important;
}

.w-40 {
  width: 42%;
}

.w-60 {
  width: 58%;
}

.job-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

#jobs th {
  background-color: rgba(17, 1, 1, 0.9);
  color: azure;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: nowrap;
}

#jobs {
  th, td {
  border: 1px solid rgba(34, 32, 32, 0.9);;
  padding: 5px;
  text-align: left;
  font-size: 12px;
  }
}

#jobsPrint th {
  background-color: rgba(17, 1, 1, 0.9);
  color: azure;
  padding-top: 2px;
  padding-bottom: 2px;
  white-space: nowrap;
}

#jobsPrint {
  th, td {
  border: 1px solid rgba(34, 32, 32, 0.9);;
  padding: 2px;
  text-align: left;
  font-size: 12px;
  }
}

.smaller-font {
  font-size: 9px;
  line-height: 10px;
}
.checkbox-small {
  font-size: 12px;
  color: #000;
}
</style>
